import styled, { css } from 'styled-components'

import { HeroArrow } from '../Icons'
import { Breakpoints, Colors, Sizes } from '@/styles'
import { Wordmark } from '@all-turtles/components'
import { Width } from '@/components'

const paddingTopMobile = '172px'
const paddingTopTablet = '192px'
const paddingTopDesktop = '337px'
export const marginDesktop = 40

export const HeroComponent = styled.div`
  margin-bottom: 132px;
  display: flex;
  flex-direction: column;
  padding-top: calc(${paddingTopMobile} + ${Sizes.navHeight});
  padding-bottom: 55px;

  @media (min-width: ${Breakpoints.tablet}) {
    padding-bottom: 0;
    padding-top: calc(${paddingTopTablet} + ${Sizes.navHeight});
    margin-bottom: 132px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    padding-top: ${paddingTopDesktop};
    margin-bottom: 334px;
  }
`

export const StyledWidth = styled(Width)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 10px;
`

export const StyledWordmark = styled(Wordmark)`
  width: 74px;
  height: 33px;
  transition: all 200ms ease;

  @media (min-width: ${Breakpoints.tablet}) {
    width: ${props => (props.small ? '90px' : '97px')};
    height: ${props => (props.small ? '40px' : '43px')};
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: ${props => (props.small ? '90px' : '110px')};
    height: ${props => (props.small ? '40px' : '49px')};
  }
`

export const WordmarkContainer = styled.div`
  height: ${Sizes.navHeight};
  position: absolute;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${Sizes.xPaddingMobile};
  top: ${props =>
    props.sticky ? 0 : `calc(${paddingTopMobile} + ${Sizes.navHeight} + 16px)`};

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 ${Sizes.xPaddingTablet};
    top: ${props =>
      props.sticky ? 0 : `calc(${paddingTopTablet} + ${Sizes.navHeight})`};
  }

  @media (min-width: ${Breakpoints.desktop}) {
    top: ${props =>
      props.sticky ? 0 : `calc(${paddingTopDesktop} - ${Sizes.navHeight})`};
  }

  ${props =>
    props.sticky &&
    css`
      position: fixed;
    `}
`

export const StyledDiv = styled.div``

export const Title = styled.h1`
  max-width: 692px;
  margin-bottom: 24px;
  margin-top: 0;
  padding-top: 10px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 67px;
    padding-top: 30px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 87px;
    padding-top: ${marginDesktop}px;
  }
`

export const NavContainer = styled.div`
  background-color: ${Colors.offWhite};
  min-height: ${Sizes.navHeight};
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 6;
`

export const StyledHeroArrow = styled(HeroArrow)`
  width: 29px;
  height: 87px;
  margin-left: 10px;

  @media (min-width: ${Breakpoints.tablet}) {
    width: 37px;
    height: 162px;
    margin-left: 4px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: auto;
    height: auto;
  }
`
