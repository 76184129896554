import React, { useRef, useState, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import * as S from './styles'
import { Width, Navigation } from '@/components'

const HeroComponent = ({ title }) => {
  const wordmarkRef = useRef()
  const titleRef = useRef()
  const [sticky, setSticky] = useState(false)

  const updateWordmarkSticky = currPos => {
    const wordmarkHeight = wordmarkRef.current.offsetHeight
    const threshold = titleRef.current.offsetTop - wordmarkHeight

    if (Math.abs(currPos.y) >= threshold) {
      setSticky(true)
    }

    if (Math.abs(currPos.y) < threshold) {
      setSticky(false)
    }
  }

  useEffect(() => {
    const rect = document.body.getBoundingClientRect()
    const currPos = {
      y: rect.top,
    }

    updateWordmarkSticky(currPos)
  }, [])

  useScrollPosition(({ currPos }) => {
    updateWordmarkSticky(currPos)
  })

  return (
    <S.HeroComponent>
      <S.NavContainer>
        <S.StyledWidth>
          <Navigation />
        </S.StyledWidth>
      </S.NavContainer>
      <S.WordmarkContainer ref={wordmarkRef} sticky={sticky}>
        <S.StyledDiv>
          <S.StyledWordmark small={sticky.toString()} alt="All Turtles" />
        </S.StyledDiv>
      </S.WordmarkContainer>
      <Width ref={titleRef}>
        <S.Title>{title}</S.Title>
        <S.StyledHeroArrow role="none" />
      </Width>
    </S.HeroComponent>
  )
}

export default HeroComponent
